.container {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  overflow: hidden;
  padding: 1rem;

  & + .container {
    margin-top: 1rem;
  }
}

.row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col {
    flex: 1 0 0%;

    & + .col {
      margin-top: 1rem;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .col {
      & + .col {
        margin-top: 0;
      }
    }
  }
}

.errorContainer {
  margin-bottom: 1rem;
}

.errorList {
  margin-bottom: 0;
}

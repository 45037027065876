.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  justify-content: center;
}

.categories .col {
  width: 100%;
  padding: 0.5rem;
  flex-shrink: 0;
  max-width: 100%;
}

.horseCategory {
  position: relative;
  overflow: hidden;
  padding: 2rem 1.75rem;

  height: 250px;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background-position: center center;
  background-size: cover;
}

.horseCategory::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.4) 35%,
    rgba(0, 212, 255, 0) 100%
  );
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

@media (hover: hover) {
  .horseCategory:hover {
    cursor: pointer;
  }

  .horseCategory:hover::before {
    opacity: 1;
  }

  .horseCategory:hover .title {
    transform: translateY(-0.5rem);
  }

  .horseCategory:hover .viewResults {
    transform: translate(0.5rem, -0.5rem);
  }
}

.horseCategory .title {
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  transition: transform 0.2s ease-in-out;
}

.horseCategory .viewResults {
  position: relative;
  color: #ffd700;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.25rem;

  display: flex;
  align-items: center;

  transition: transform 0.2s ease-in-out;
}

.horseCategory .icon {
  width: 16px;
  height: 16px;
  margin-left: 0.25rem;
}

@media (min-width: 768px) {
  .horseCategory {
    height: 350px;
  }

  .horseCategory .title {
    font-size: 2.5rem;
  }

  .categories .col {
    flex: 0 0 auto;
    width: 50%;
  }
}

.header {
  width: 100%;

  .container {
    max-width: 1320px;
    width: 100%;
    margin: auto;
    padding: 0.5rem 1rem;

    display: flex;
    align-items: center;
  }

  .logo {
    display: block;
    height: 200px;
    cursor: pointer;
    margin-right: 1rem;
  }

  .links {
    border-left: 1px solid #ccc;
    padding-left: 1rem;

    a {
      color: #777;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 1.2rem;
      margin-right: 1rem;
      transition: color 0.2s;

      &:hover {
        color: #212121;
      }
    }
  }

  .right {
    margin-left: auto;
  }
}

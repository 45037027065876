.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -1rem;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.col {
  flex: 1 0 0%;
}

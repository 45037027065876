.textInput {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #eee;
  background-clip: padding-box;
  border: 2px solid #eee;
  appearance: none;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  &:focus {
    border-color: #212529;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }

  &.hasError {
    border-color: #f44336;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.helpText {
  font-size: 12px;
  color: #fff;
  margin-top: 8px;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.title {
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: 500;
}

.usersTable {
  margin-top: 2rem;
}

.loaderContainer {
  text-align: center;
}

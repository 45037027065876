.button {
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #fff;
  background-color: #212529;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0;
  position: relative;

  &:not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: darken($color: #212529, $amount: 10);
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #212529;
    pointer-events: none;
    box-shadow: none;
    opacity: 0.65;
  }

  &.danger {
    background-color: #f44336;

    &:not(:disabled):not(.disabled):hover {
      background-color: #d32f2f;
    }
  }

  &.success {
    background-color: #4caf50;

    &:not(:disabled):not(.disabled):hover {
      background-color: #388e3c;
    }
  }

  &.light {
    color: #333;
    background-color: #eeeeee;

    &:not(:disabled):not(.disabled):hover {
      background-color: #d6d6d6;
    }
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &.block {
    width: 100%;
  }

  &.small {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    height: 32px;
  }

  .loader {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .isLoadingText {
    opacity: 0;
  }
}

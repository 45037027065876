.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.loaderContainer {
  text-align: center;
}

.title {
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noData {
  color: #777;
  text-align: center;
}

.col4 {
  flex: 0 0 auto;

  @media (min-width: 768px) {
    width: 33.33333333%;
  }
}

.link {
  display: block;
  margin-top: 0.25rem;
}

.info {
  .name {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .date,
  .location {
    color: #777;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
      flex-shrink: 0;
    }
  }

  .image {
    width: 100%;
    border-radius: 6px;
  }

  .poster {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }
}

.usersTable {
  margin-top: 2rem;
}

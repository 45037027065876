.accordion {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  overflow: hidden;

  & + .accordion {
    margin-top: 1rem;
  }

  .header {
    padding: 1rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    border-radius: 9px 9px 0 0;
    justify-content: space-between;
  }

  .header .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    transition: transform 0.15s ease-in-out;
  }

  .header .icon.active {
    transform: rotate(180deg);
  }

  .header.active {
    background-color: #efefef;
  }

  @media (hover: hover) {
    &:hover {
      border-color: #ddd;
    }

    &:hover .header {
      background-color: #efefef;
      cursor: pointer;
    }
  }

  .content {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .date {
    margin-top: 0.25rem;
    color: #777;
    display: flex;
    align-items: center;

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;
    }
  }
}

.competitionLink {
  padding: 1rem;
  border-top: 1px solid transparent;

  & + .competitionLink {
    border-color: #eee;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 1rem 2rem;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.header {
  height: 350px;
  padding: 1rem;
  margin: 0 -1rem;
  background-position: center center;
  background-size: cover;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;
}

.header .title {
  position: relative;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
}

.header::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.section {
  margin-top: 2rem;
}

.section .title {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.link {
  display: flex;
  align-items: center;
}

.link + .link {
  margin-top: 0.25rem;
}

.link .icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  color: #000;
}

.competitions {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.competitions .col {
  width: 100%;
  padding: 0.5rem;
  flex-shrink: 0;
  max-width: 100%;
}

.nextCompetition {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 1rem;
  background-color: #fff;
}

@media (hover: hover) {
  .nextCompetition:hover {
    background-color: #efefef;
    border-color: #ddd;
    cursor: pointer;
  }
}

.nextCompetition .image {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 6px;
}

.nextCompetition .info .name {
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
}

.nextCompetition .info .date,
.nextCompetition .info .location {
  color: #777;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.nextCompetition .info .date .icon,
.nextCompetition .info .location .icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .header {
    margin: 2rem 0 0;
    border-radius: 10px;
  }

  .competitions .col {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}

.noData {
  margin-top: 2rem;
  color: #777;
  text-align: center;
}

.modalOpened {
  overflow: hidden;
}

.modalDialog {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
}

.modalContent {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-width: 550px;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .modalBody {
    overflow-y: auto;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  overflow-x: hidden;
  overflow-y: auto;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

:global .ReactModal__Overlay--after-open {
  opacity: 1;
}

:global .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modalBody {
  padding: 0.5rem 1rem 1rem;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0.5rem;
  flex-shrink: 0;

  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.closeButton {
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;

  width: 40px;
  height: 40px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
  padding-bottom: 2px;
  padding-left: 2px;
  color: #000;

  &:hover {
    background-color: #eee;
  }
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.date {
  color: #777;
}

.description {
  margin-top: 1rem;

  p,
  img,
  span {
    display: block;
  }
}

.cont + .cont {
  margin-top: 1rem;
}

.table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;

  th,
  td {
    padding: 1rem;
    vertical-align: middle;
    border-top: 1px solid #ddd;
  }

  thead th {
    vertical-align: bottom;
    border-top: 0;
    padding-top: 0;
    border-bottom: 1px solid #ddd;
  }

  &.tableHover > tbody > tr:hover > * {
    background-color: #f7f7f7;
    cursor: pointer;
  }
}

.tableResponsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

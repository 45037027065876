.loader {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid #212529;
  border-right-color: transparent;
  border-radius: 50%;
  animation: loaderAnim 0.75s linear infinite;
}

.loaderMedium {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.2em;
}

.loaderSmall {
  width: 1rem;
  height: 1rem;
  border-width: 0.15em;
}

@keyframes loaderAnim {
  to {
    transform: rotate(360deg);
  }
}
